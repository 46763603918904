import PropTypes from 'prop-types';
import {createElement} from 'react';
import {ErrorIcon, SuccessIcon, WarningIcon} from './assets/images';
import styles from "./assets/Toast.module.scss";

const Toast = ({type, message}) => {

    let header, icon;
    switch (type) {
        case 'success':
            header = 'Success';
            icon = SuccessIcon;
            break;
        case 'warn':
            header = 'Warning';
            icon = WarningIcon;
            break;
        default:
            header = 'Error !';
            icon = ErrorIcon;
            break;
    }

    return (
        <div className={styles.toast__container}>
            <div className={styles.toast__icon}>
                {createElement(icon)}
            </div>
            <div>
                <p className={styles.toast__header}> {header} </p>
                <p className={styles.toast__details}> {message} </p>
            </div>
        </div>
    );
}

/* --------------- props --------------- */
Toast.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
}

Toast.defaultProps = {
    type: 'error'
}


export default Toast;
