import { useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import useMessages from "./useMessages";

export default function useAuth() {

    // initialize
    const history = useHistory();
    const [user, setUser] = useState();
    const { error } = useMessages();

    // functions
    /**
     * Generate user token
     * @param {object} userCredentials username:password
     */
    async function login(userCredentials, setLoading) {

        try {

            // load, login
            setLoading(true);
            const cognitoUserCredentials = {
                Username: userCredentials.email,
                Password: userCredentials.password
            }

            const cognitoUser = new CognitoUser({
                Username: cognitoUserCredentials.Username,
                Pool: new CognitoUserPool({
                    UserPoolId: 'me-south-1_nU17sEIuP',
                    ClientId: 'hhcs3cs0k9bhcf19n0aba95u7'
                })
            })

            const authenticationDetails = new AuthenticationDetails(cognitoUserCredentials);

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {

                    const { accessToken: { jwtToken }, idToken: { payload } } = result;
                    console.log(result)
                    console.log(payload)
                    // save user credentials
                    const firstname = payload['custom:first_name'] || '';
                    const username = firstname + ' ' + payload['custom:last_name'] || '';
                    const email = payload['email'];
                    const group = payload['cognito:groups'][0];
                    localStorage.lms_user = JSON.stringify({ firstname, username, email, group });
                    localStorage.lms_token = jwtToken;

                    // go to home page
                    process.nextTick(() => history.push('/'));
                    setLoading(false);
                },
                onFailure: (ex) => {
                    error(ex.response?.data || ex?.message || ex);
                    setLoading(false);
                }
            })

        } catch (exception) {
            error(exception.response?.data || exception?.message || exception);
            setLoading(false);
        }

    }

    /**
     * Get logged in user details
     */
    function getUser() {

        if (!user && localStorage.lms_user) {
            const loggedUser = JSON.parse(localStorage.lms_user);
            setUser(loggedUser);
            return loggedUser;
        }
        return user || {};

    }

    /**
     * Decode user object from JWT token
     */
    function getTokenDetails() {

        if (!localStorage.lms_token || localStorage.lms_token === '') {
            return null;
        }

        // token processing
        const base64Url = localStorage.lms_token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        // Json extracted from JWT token
        return JSON.parse(jsonPayload);

    }

    /**
     * Check token validity
     */
    function isTokenExpired() {

        const tokenDetails = getTokenDetails();

        // case of no token
        if (!tokenDetails) {
            return true;
        }

        // check existing token
        const { exp } = tokenDetails;
        const expiryDate = new Date(0);
        expiryDate.setUTCSeconds(exp);
        const msDiff = (new Date()).getTime() - expiryDate.getTime();
        return msDiff > 0;

    }

    /**
     * Get default headers for authenticated APIs
     */
    function getHeaders() {

        if (isTokenExpired()) {
            logout();
        }

        return {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.lms_token}`,
        }

    }

    /**
     * Logout and clear cache
     */
    function logout() {

        localStorage.removeItem('lms_token');
        localStorage.removeItem('lms_user');

        // go to home page
        process.nextTick(() => history.push('/sign-in'));

    }

    // return utils
    return {
        login,
        getUser,
        getTokenDetails,
        isTokenExpired,
        getHeaders,
        logout
    }
}
