import React, { useState, useEffect } from "react";
import styles from "./assets/OrdersExport.module.scss";
import { Button, DateRange } from "homzmart-components";
import useMessages from "../../../../hooks/useMessages";
import { downloadFile } from "../../../../services/files.service";
import useAuth from "../../../../hooks/useAuth";
import * as Pusher from "pusher-js";


export function OrdersExport() {

    // ----------------- data & configs ----------------- //

    // states
    const [rangeValue, setRangeValue] = useState();
    const [loading, setLoading] = useState(false);
    const { error, success } = useMessages();
    const { getHeaders } = useAuth();
    const { getUser } = useAuth();
    const { email } = getUser();

    function callPusher() {

        var pusher = new Pusher('ac75d805a5b4f7e62a65', {
            cluster: 'eu'
        });
        var channel = pusher.subscribe(email);
        return channel.bind('Orders', function (data) {
            // window.open(data.message)
            window.location.href = data.message
            setLoading(false)
        });
    }

    // useEffect(() => {
    //     callPusher();
    // }, []);


    async function downloadCsvFile() {
        if (rangeValue?.from && rangeValue?.to) {
            try {
                setLoading(true);
                callPusher();
                await downloadFile('/api/orders', { ...rangeValue, email }, getHeaders(), { error, success });
                console.log("Your requested data will be exported")
                // success("Your requested data will be exported")
            } catch (e) {
                // error("Couldn't export requested orders")
                console.log(e)
            } finally {
                // setLoading(false)
                // console.log("FINALLY  ==>");
                // await setTimeout(10000);
                // window.location.href = '/';
            }
        }
        else {
            error("please select the desired date")
        }
    }

    return (
        <fieldset>
            <legend> Order Export</legend>
            <DateRange
                format="YYYY-MM-dd"
                // maxInterval = {90}
                label="Order creation date"
                value={rangeValue}
                onChange={setRangeValue}
            />
            <div className={styles.Export__Button}>
                <Button
                    className={loading ? styles['Export__Button--loading'] : ''}
                    loading={loading}
                    onClick={downloadCsvFile}>
                    Export
                </Button>
            </div>
            {/* <div style={{
                display: 'block', width: '100%', padding: '0 7px' , color: '#767676',
                textTransform: 'capitalize'
            }}>The maximum duration for exporting is 90 days</div> */}
        </fieldset>
    );

}