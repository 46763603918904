import { toast } from 'react-toastify';
import { Toast } from '../components/Toast';

export default function useMessages() {
    // functions
    const error = (message) => {
        toast.error(
            <Toast
                type="error"
                message={message}
            />,
            {
                className: "toast__base toast__error",
            }
        );
    }
    const warn = (message) => {
        toast.warn(
            <Toast
                type="warn"
                message={message}
            />,
            {
                className: "toast__base toast__warn",
            }
        );
    }
    const success = (message) => {
        toast.success(
            <Toast
                type="success"
                message={message}
            />,
            {
                className: "toast__base toast__success",
            }
        );
    }
    // return utils
    return {
        error,
        warn,
        success
    }
}

