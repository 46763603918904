import classNamesBind from 'classnames/bind';
import PropTypes from 'prop-types';
import {Logo} from '../../static';
import Page from '../Page/Page';
import styles from "./assets/AuthPage.module.scss";
import {OfficeIcon} from "./assets/images";

const AuthPage = ({className, children}) => {

    // initialize
    let classes = classNamesBind.bind(styles);

    return (
        <Page className={classes(className, 'auth__container')}>
            <div className={styles.auth__wrapper}>
                <div className={styles.auth__grid}>
                    <div className={styles.auth__illustration}>
                        <OfficeIcon/>
                    </div>
                    <span className={styles.auth__separator}/>
                    <div className={styles.auth__content}>

                        {/* Brand */}
                        <Logo className={styles.auth__logo}/>
                        <h4 className={styles.auth__logometa}>
                            Reporting System KSA
                        </h4>

                        {/* Page content */}
                        {children}

                    </div>
                </div>
            </div>
        </Page>
    );

}

AuthPage.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
}

export default AuthPage;
