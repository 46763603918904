// import * as Pusher from "pusher-js";
import React, { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import Page from "../../layouts/Page/Page";
import styles from "./assets/Extract.module.scss";
import { OrderItemsExport } from "./components/OrderItemsExport/OrderItemsExport";
import { OrderItemStatusExport } from "./components/OrderItemStatusExport/OrderItemStatusExport";
import { OrdersExport } from "./components/OrdersExport/OrdersExport";
// import { CustomerCartExport } from "./components/CustomerCartExport/CustomerCartExport";
// import { CustomerExport } from "./components/CustomerExport/CustomerExport";
import { Card, DataGrid } from "homzmart-components";
// import { SCorderItemExport } from "./components/SCorderItemExport/SCorderItemExport";




export function Extract() {
    const { getUser } = useAuth();
    const { email, group } = getUser();



    if (group == "Executive") {

        return (
            <Page>
                <Card
                    className=
                    {styles.Page__Title}> Data Extraction
                    <h1 className={styles.Page__body}> Beta Version</h1>

                </Card>
                {/* <CustomerExport /> */}
                <OrdersExport />
                <OrderItemsExport />
                <OrderItemStatusExport />
                {/* <SCorderItemExport /> */}
                {/* <CustomerCartExport /> */}
                {/* <SellerExport/>
            {/* <ProductsExport/>} */}

            </Page>
        );
    } else {

        return (
            <Page>
                <Card
                    className=
                    {styles.Page__Title}> Data Extraction
                    <h1 className={styles.Page__body}> Beta Version</h1>

                </Card>
                <OrdersExport />
                <OrderItemsExport />
                <OrderItemStatusExport />
                {/* <SCorderItemExport /> */}

            </Page>
        );

    }

}
