import axios from "axios";



/**
 * Download a file from the backend
 *
 * @param {string} url the file download url
 * @param {object} params filter criteria
 */
export async function downloadFile(url, params, inputHeaders, {error, success}) {



      const {headers, data} = await axios.get(url, {  
        method: 'GET',
        params: params,
        headers: inputHeaders
    })

    
    }







