import classNamesBind from 'classnames/bind';
import React from "react";
import { Route, Switch, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from './components/ProtectedRoute';
import AppLayout from './layouts/AppLayout/AppLayout';
import { NotFound } from './pages/NotFound/NotFound';
import menuItems from './routes';
import styles from "./styles/App.module.scss";


const Outlet = ({secondary}) => {

// console.log(menuItems)

    // initialize
    let location = useLocation();
    let classes = classNamesBind.bind(styles);

    let MenuItems = menuItems();


    return (
        <div className={classes('app__page', {'app__page--secondary': secondary})}>
            <Switch location={location}>
                {MenuItems.map(({path, component, unprotected}, i) => {
                    if (component) {

                        if (unprotected) {
                            return <Route key={i} path={path} exact component={component}/>
                        } else {
                            return <ProtectedRoute key={i} path={path} exact component={component}/>
                        }
                    } else {
                        return null
                    }
                })}
                <Route component={NotFound}/>
            </Switch>
        </div>
    );
}

export default function App() {

    let MenuItems = menuItems();

    let {pathname} = useLocation();
    const isPublic = MenuItems
        .filter(route => route.unprotected)
        .map(route => route.path)
        .includes(pathname);

    return (
        <div className="App">
            {isPublic ? <Outlet secondary/> : <AppLayout> <Outlet/> </AppLayout>}
            <ToastContainer
                position="top-right"
                hideProgressBar={true}
                autoClose={2000}
                limit={2}
            />
            {/* <BetaIndicator/> */}
        </div>
    );
}
