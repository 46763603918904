import axios from "axios";
import { Card, DataGrid } from "homzmart-components";
import React, { useEffect, useState } from "react";
import useAuth from '../../hooks/useAuth';
import useMessages from "../../hooks/useMessages";
import Page from "../../layouts/Page/Page";
import styles from './assets/RealTime.module.scss';
import { useHistory } from 'react-router-dom';



const RealTime = () => {

    const [allData, setAllData] = useState({ OrdH: [], ItemsH: [], GmvH: [], EffH: [], lOrd:  [] , curDate: "" })
    const [allDataWeb, setAllDataWeb] = useState({ OrdH: [], ItemsH: [], GmvH: [], EffH: [] })
    const [allDataApp, setAllDataApp] = useState({ OrdH: [], ItemsH: [], GmvH: [], EffH: [] })
    const [allDataCod, setAllDataCod] = useState({ OrdH: [], ItemsH: [], GmvH: [], EffH: [] })
    const [allDataOp, setAllDataOp] = useState({ OrdH: [], ItemsH: [], GmvH: [], EffH: [] })
    const [allDataGoogle, setAllDataGoogle] = useState({ activeUsersAll: [[]]})

    const [loading, setLoading] = useState(false);
    const [loadingWeb, setLoadingWeb] = useState(false);
    const [loadingApp, setLoadingApp] = useState(false);
    const [loadingCod, setLoadingCod] = useState(false);
    const [loadingOp, setLoadingOp] = useState(false);

    const { getHeaders } = useAuth();
    const { error, success } = useMessages();
    const [date, setDate] = useState(new Date().toLocaleString('en-US',{ timeZone:  'Asia/Riyadh'}));

    useEffect(() => {
        try {
            axios.get('api/google', {
                headers: getHeaders()
            })
                .then((response) => {
                    setAllDataGoogle(response.data);
                })
        } catch (e) {
            error("Couldn't export requested data")
        }
    }, []);




    useEffect(() => {
        try {
            setLoading(true);
            axios.get('api/realtime', {
                headers: getHeaders()
            })
                .then((response) => {
                    setAllData(response.data);
                    setLoading(false)
                })
        } catch (e) {
            error("Couldn't export requested data")
            setLoading(false)
        }
    }, []);

    useEffect(() => {
        try {
            setLoadingWeb(true);
            axios.get('api/realtime/web', {
                headers: getHeaders()
            })
                .then((response) => {
                    setAllDataWeb(response.data);
                    setLoadingWeb(false)
                })
        } catch (e) {
            error("Couldn't export requested data")
            setLoading(false)
        }
    }, []);

    useEffect(() => {
        try {
            setLoadingApp(true);
            axios.get('api/realtime/app ', {
                headers: getHeaders()
            })
                .then((response) => {
                    setAllDataApp(response.data);
                    setLoadingApp(false)
                })
        } catch (e) {
            error("Couldn't export requested data")
            setLoading(false)
        }
    }, []);

    useEffect(() => {
        try {
            setLoadingCod(true);
            axios.get('api/realtime/cod', {
                headers: getHeaders()
            })
                .then((response) => {
                    setAllDataCod(response.data);
                    setLoadingCod(false)
                })
        } catch (e) {
            error("Couldn't export requested data")
            setLoading(false)
        }
    }, []);

    useEffect(() => {
        try {
            setLoadingOp(true);
            axios.get('api/realtime/op', {
                headers: getHeaders()
            })
                .then((response) => {
                    setAllDataOp(response.data);
                    setLoadingOp(false)
                })
        } catch (e) {
            error("Couldn't export requested data")
            setLoading(false)
        }
    }, []);


    const dataAll = [
        {
            dimension: "Today",
            items: allData.ItemsH[0],
            orders: allData.OrdH[0],
            gross_margin_value: allData.GmvH[0]?.toLocaleString(),
            items_orders: (allData.ItemsH[0] / allData.OrdH[0]).toFixed(4),
            average_item_value: Math.round(allData.GmvH[0] / allData.ItemsH[0]),
            average_order_value: Math.round(allData.GmvH[0] / allData.OrdH[0]),
            efficiency: (allData.EffH[0] * 100)?.toFixed(2) + "%",
        },
        {
            dimension: "Yesterday",
            items: allData.ItemsH[1],
            orders: allData.OrdH[1],
            gross_margin_value: allData.GmvH[1]?.toLocaleString(),
            items_orders: (allData.ItemsH[1] / allData.OrdH[1]).toFixed(2),
            average_item_value: Math.round(allData.GmvH[1] / allData.ItemsH[1]),
            average_order_value: Math.round(allData.GmvH[1] / allData.OrdH[1]),
            efficiency: (allData.EffH[1] * 100)?.toFixed(2) + "%",
        },
        {
            dimension: "Difference",
            items: (allData.ItemsH[2] * 100)?.toFixed(2) + "%",
            orders: (allData.OrdH[2] * 100)?.toFixed(2) + "%",
            gross_margin_value: (allData.GmvH[2] * 100)?.toFixed(2) + "%",
            items_orders: (((allData.ItemsH[0] / allData.OrdH[0]) / (allData.ItemsH[1] / allData.OrdH[1])) - 1)?.toFixed(4) + "%",
            average_item_value: (((Math.round(allData.GmvH[0] / allData.ItemsH[0])) / Math.round(allData.GmvH[1] / allData.ItemsH[1]) - 1) * 100)?.toFixed(4) + "%",
            average_order_value: (((Math.round(allData.GmvH[0] / allData.OrdH[0])) / Math.round(allData.GmvH[1] / allData.OrdH[1]) - 1) * 100)?.toFixed(4) + "%",
            efficiency: ((allData.EffH[0] - allData.EffH[1]) * 100)?.toFixed(2) + "%",
        },
        {
            dimension: "MTD",
            items: allData.ItemsH[3],
            orders: allData.OrdH[3],
            gross_margin_value: allData.GmvH[3]?.toLocaleString(),
            items_orders: (allData.ItemsH[3] / allData.OrdH[3]).toFixed(2),
            average_item_value: Math.round(allData.GmvH[3] / allData.ItemsH[3]),
            average_order_value: Math.round(allData.GmvH[3] / allData.OrdH[3]),
            efficiency: (allData.EffH[3] * 100)?.toFixed(2) + "%",
        }

    ]

    const dataWeb = [
        {
            dimension: "Today",
            items: allDataWeb.ItemsH[0],
            orders: allDataWeb.OrdH[0],
            gross_margin_value: allDataWeb.GmvH[0]?.toLocaleString(),
            items_orders: (allDataWeb.ItemsH[0] / allDataWeb.OrdH[0]).toFixed(2),
            average_item_value: Math.round(allDataWeb.GmvH[0] / allDataWeb.ItemsH[0]),
            average_order_value: Math.round(allDataWeb.GmvH[0] / allDataWeb.OrdH[0]),
            efficiency: (allDataWeb.EffH[0] * 100).toFixed(2) + "%",
        },
        {
            dimension: "Yesterday",
            items: allDataWeb.ItemsH[1],
            orders: allDataWeb.OrdH[1],
            gross_margin_value: allDataWeb.GmvH[1]?.toLocaleString(),
            items_orders: (allDataWeb.ItemsH[1] / allDataWeb.OrdH[0]).toFixed(2),
            average_item_value: Math.round(allDataWeb.GmvH[1] / allDataWeb.ItemsH[1]),
            average_order_value: Math.round(allDataWeb.GmvH[1] / allDataWeb.OrdH[1]),
            efficiency: (allDataWeb.EffH[1] * 100).toFixed(2) + "%",
        },
        {
            dimension: "Difference",
            items: (allDataWeb.ItemsH[2] * 100)?.toFixed(2) + "%",
            orders: (allDataWeb.OrdH[2] * 100)?.toFixed(2) + "%",
            gross_margin_value: (allDataWeb.GmvH[2] * 100)?.toFixed(2) + "%",
            items_orders: (((allDataWeb.ItemsH[0] / allDataWeb.OrdH[0]) / (allDataWeb.ItemsH[1] / allDataWeb.OrdH[1])) - 1)?.toFixed(4) + "%",
            average_item_value: (((Math.round(allDataWeb.GmvH[0] / allDataWeb.ItemsH[0])) / Math.round(allDataWeb.GmvH[1] / allDataWeb.ItemsH[1]) - 1) * 100)?.toFixed(4) + "%",
            average_order_value: (((Math.round(allDataWeb.GmvH[0] / allDataWeb.OrdH[0])) / Math.round(allDataWeb.GmvH[1] / allDataWeb.OrdH[1]) - 1) * 100)?.toFixed(4) + "%",
            efficiency: ((allDataWeb.EffH[0] - allDataWeb.EffH[1]) * 100).toFixed(2) + "%",
        }

    ]

    const dataApp = [
        {
            dimension: "Today",
            items: allDataApp.ItemsH[0],
            orders: allDataApp.OrdH[0],
            gross_margin_value: allDataApp.GmvH[0]?.toLocaleString(),
            items_orders: (allDataApp.ItemsH[0] / allDataApp.OrdH[0]).toFixed(2),
            average_item_value: Math.round(allDataApp.GmvH[0] / allDataApp.ItemsH[0]),
            average_order_value: Math.round(allDataApp.GmvH[0] / allDataApp.OrdH[0]),
            efficiency: (allDataApp.EffH[0] * 100).toFixed(2) + "%",
        },
        {
            dimension: "Yesterday",
            items: allDataApp.ItemsH[1],
            orders: allDataApp.OrdH[1],
            gross_margin_value: allDataApp.GmvH[1]?.toLocaleString(),
            items_orders: (allDataApp.ItemsH[1] / allDataApp.OrdH[1]).toFixed(2),
            average_item_value: Math.round(allDataApp.GmvH[1] / allDataApp.ItemsH[1]),
            average_order_value: Math.round(allDataApp.GmvH[1] / allDataApp.OrdH[1]),
            efficiency: (allDataApp.EffH[1] * 100).toFixed(2) + "%",
        },
        {
            dimension: "Difference",
            items: (allDataApp.ItemsH[2] * 100)?.toFixed(2) + "%",
            orders: (allDataApp.OrdH[2] * 100)?.toFixed(2) + "%",
            gross_margin_value: (allDataApp.GmvH[2] * 100)?.toFixed(2) + "%",
            items_orders: (((allDataApp.ItemsH[0] / allDataApp.OrdH[0]) / (allDataApp.ItemsH[1] / allDataApp.OrdH[1])) - 1)?.toFixed(4) + "%",
            average_item_value: (((Math.round(allDataApp.GmvH[0] / allDataApp.ItemsH[0])) / Math.round(allDataApp.GmvH[1] / allDataApp.ItemsH[1]) - 1) * 100)?.toFixed(4) + "%",
            average_order_value: (((Math.round(allDataApp.GmvH[0] / allDataApp.OrdH[0])) / Math.round(allDataApp.GmvH[1] / allDataApp.OrdH[1]) - 1) * 100)?.toFixed(4) + "%",
            efficiency: ((allDataApp.EffH[0] - allDataApp.EffH[1]) * 100).toFixed(2) + "%",
        }

    ]

    const dataCod = [
        {
            dimension: "Today",
            items: allDataCod.ItemsH[0],
            orders: allDataCod.OrdH[0],
            gross_margin_value: allDataCod.GmvH[0]?.toLocaleString(),
            items_orders: (allDataCod.ItemsH[0] / allDataCod.OrdH[0]).toFixed(2),
            average_item_value: Math.round(allDataCod.GmvH[0] / allDataCod.ItemsH[0]),
            average_order_value: Math.round(allDataCod.GmvH[0] / allDataCod.OrdH[0]),
            efficiency: (allDataCod.EffH[0] * 100)?.toFixed(2) + "%",
        },
        {
            dimension: "Yesterday",
            items: allDataCod.ItemsH[1],
            orders: allDataCod.OrdH[1],
            gross_margin_value: allDataCod.GmvH[1]?.toLocaleString(),
            items_orders: (allDataCod.ItemsH[1] / allDataCod.OrdH[1]).toFixed(2),
            average_item_value: Math.round(allDataCod.GmvH[1] / allDataCod.ItemsH[1]),
            average_order_value: Math.round(allDataCod.GmvH[1] / allDataCod.OrdH[1]),
            efficiency: (allDataCod.EffH[1] * 100)?.toFixed(2) + "%",
        },
        {
            dimension: "Difference",
            items: (allDataCod.ItemsH[2] * 100)?.toFixed(2) + "%",
            orders: (allDataCod.OrdH[2] * 100)?.toFixed(2) + "%",
            gross_margin_value: (allDataCod.GmvH[2] * 100)?.toFixed(2) + "%",
            items_orders: (((allDataCod.ItemsH[0] / allDataCod.OrdH[0]) / (allDataCod.ItemsH[1] / allDataCod.OrdH[1])) - 1)?.toFixed(4) + "%",
            average_item_value: (((Math.round(allDataCod.GmvH[0] / allDataCod.ItemsH[0])) / Math.round(allDataCod.GmvH[1] / allDataCod.ItemsH[1]) - 1) * 100)?.toFixed(4) + "%",
            average_order_value: (((Math.round(allDataCod.GmvH[0] / allDataCod.OrdH[0])) / Math.round(allDataCod.GmvH[1] / allDataCod.OrdH[1]) - 1) * 100)?.toFixed(4) + "%",
            efficiency: ((allDataCod.EffH[0] - allDataCod.EffH[1]) * 100).toFixed(2) + "%",
        }

    ]

    const dataOp = [
        {
            dimension: "Today",
            items: allDataOp.ItemsH[0],
            orders: allDataOp.OrdH[0],
            gross_margin_value: allDataOp.GmvH[0]?.toLocaleString(),
            items_orders: (allDataOp.ItemsH[0] / allDataOp.OrdH[0]).toFixed(2),
            average_item_value: Math.round(allDataOp.GmvH[0] / allDataOp.ItemsH[0]),
            average_order_value: Math.round(allDataOp.GmvH[0] / allDataOp.OrdH[0]),
            efficiency: (allDataOp.EffH[0] * 100)?.toFixed(2) + "%",
        },
        {
            dimension: "Yesterday",
            items: allDataOp.ItemsH[1],
            orders: allDataOp.OrdH[1],
            gross_margin_value: allDataOp.GmvH[1]?.toLocaleString(),
            items_orders: (allDataOp.ItemsH[1] / allDataOp.OrdH[1]).toFixed(2),
            average_item_value: Math.round(allDataOp.GmvH[1] / allDataOp.ItemsH[1]),
            average_order_value: Math.round(allDataOp.GmvH[1] / allDataOp.OrdH[1]),
            efficiency: (allDataOp.EffH[1] * 100)?.toFixed(2) + "%",
        },
        {
            dimension: "Difference",
            items: (allDataOp.ItemsH[2] * 100)?.toFixed(2) + "%",
            orders: (allDataOp.OrdH[2] * 100)?.toFixed(2) + "%",
            gross_margin_value: (allDataOp.GmvH[2] * 100)?.toFixed(2) + "%",
            items_orders: (((allDataOp.ItemsH[0] / allDataOp.OrdH[0]) / (allDataOp.ItemsH[1] / allDataOp.OrdH[1])) - 1)?.toFixed(4) + "%",
            average_item_value: (((Math.round(allDataOp.GmvH[0] / allDataOp.ItemsH[0])) / Math.round(allDataOp.GmvH[1] / allDataOp.ItemsH[1]) - 1) * 100)?.toFixed(4) + "%",
            average_order_value: (((Math.round(allDataOp.GmvH[0] / allDataOp.OrdH[0])) / Math.round(allDataOp.GmvH[1] / allDataOp.OrdH[1]) - 1) * 100)?.toFixed(4) + "%",
            efficiency: ((allDataOp.EffH[0] - allDataOp.EffH[1]) * 100).toFixed(2) + "%",
        }

    ]





    const columns = [
        {
            name: '', key: 'dimension', render: ({ value }) => {
                return (
                    <b> {value} </b>
                )
            }
        },
        { name: 'Items', key: 'items' },
        { name: 'Orders', key: 'orders' },
        { name: 'GMV', key: 'gross_margin_value' },
        { name: 'I/O', key: 'items_orders' },
        { name: 'AIV', key: 'average_item_value' },
        { name: 'AOV', key: 'average_order_value' },
        { name: "Eff%", key: 'efficiency' },
    ]

    return (
        <Page >
            <div>
            <Card style = {{display: "inline-flex",width:"350px", marginRight: "20px"}}
                className={styles.realTime__lastUpdated}>
                {date}
            </Card>

            <Card style = {{display: "inline-flex",width:"350px", marginRight: "20px"}}
                className={styles.realTime__lastUpdated}
                >
                Current Active Users <span style = {{color: 'rgb(63, 138, 89)', margin: "0 24px"}}>{allDataGoogle.activeUsersAll[0][0]} </span>
            </Card> 
            </div>

            <Card 
                className={styles.realTime__lastUpdated}
                >
                Last Order Has Been Created Since  <span style={{color: 'rgb(63, 138, 89)'}}>{Math.trunc(((Number(allData.lOrd[1])) * 60))} </span> Mins <span style={{color: 'rgb(63, 138, 89)'}}>{Math.trunc(((((Number(allData.lOrd[1])) * 60) % 1)) * 60)} </span>  Secs
            </Card>

            <DataGrid
                noPagination
                loading={loading}
                noCountInfo
                label='All in'
                totalCount={dataAll.length}
                pageSize={dataAll.length}
                data={dataAll}
                columns={columns}
            />
            <h1>
            </h1>
            <DataGrid
                noPagination
                loading={loadingWeb}
                noCountInfo
                label='Web'
                totalCount={dataWeb.length}
                pageSize={dataWeb.length}
                data={dataWeb}
                columns={columns}
            />
            <h1>
            </h1>
            <DataGrid
                noPagination
                loading={loadingApp}
                noCountInfo
                label='App'
                totalCount={dataApp.length}
                pageSize={dataApp.length}
                data={dataApp}
                columns={columns}
            />
            <h1>
            </h1>
            <DataGrid
                noPagination
                loading={loadingCod}
                label='COD'
                noCountInfo
                totalCount={dataCod.length}
                pageSize={dataCod.length}
                data={dataCod}
                columns={columns}
            />
            <h1>
            </h1>
            <DataGrid
                noPagination
                loading={loadingOp}
                noCountInfo
                label='Online Payments'
                totalCount={dataOp.length}
                pageSize={dataOp.length}
                data={dataOp}
                columns={columns}
            />
        </Page>
    );

}

export default RealTime;
