import { useEffect, useState } from 'react';
import styles from "./assets/AppLayout.module.scss";
import NavBar from './components/NavBar/NavBar';
import SideBar from './components/SideBar/SideBar';

export default function AppLayout({ children }) {

    // initialize
    const [active, setActive] = useState(false);
    const toggleSidebar = () => setActive(!active);

    useEffect(() => {
        window.addEventListener("resize", () => setActive(false));
    }, []);

    return (
        <>
            <NavBar toggleSidebar={toggleSidebar} />
            <div className={styles.layout__container}>
                {active && (
                    <div
                        onClick={() => setActive(false)}
                        className={styles.layout__overlay}
                    />
                )}
                <SideBar active={active} setActive={setActive} />
                <div id="siteContent" className={styles.layout__content}>
                    {children}
                </div>
            </div>
        </>
    )
}
