import React from "react";
import {Redirect, Route} from "react-router-dom";
import useAuth from "../hooks/useAuth";

function ProtectedRoute(props) {

    const {isTokenExpired} = useAuth();

    if (isTokenExpired()) {
        return <Redirect to="/sign-in"/>;
    } else {
        return (
            <Route {...props} />
        );
    }
}

export default ProtectedRoute;
