import { Scrollbar } from 'homzmart-components';
import PropTypes from 'prop-types';
import styles from "./assets/Page.module.scss";

const Page = ({ children, className, stopScroll }) => {

    return (
        <Scrollbar
            wide
            disabled={stopScroll}
            contentClass={[styles.page__wrapper, className].join(' ')}
        >
            {children}
        </Scrollbar>
    );

}

Page.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    className: PropTypes.string,
    onScroll: PropTypes.func
}

export default Page;