import React, {useState, useEffect} from "react";
import styles from "./assets/OrderItemsExport.module.scss";
import {Button, DateRange} from "homzmart-components";
import useMessages from "../../../../hooks/useMessages";
import {downloadFile} from "../../../../services/files.service";
import useAuth from "../../../../hooks/useAuth";
import * as Pusher from "pusher-js";


export function OrderItemsExport() {

    // ----------------- data & configs ----------------- //

    // states
    const [rangeValue, setRangeValue] = useState();
    const [loading, setLoading] = useState(false);
    const {error, warn,success } = useMessages();
    const { getHeaders } = useAuth();
    const {getUser} = useAuth();
    const {email} = getUser();

    function callPusher() {

        var pusher = new Pusher('ac75d805a5b4f7e62a65', {
            cluster: 'eu'
        });
        var channel = pusher.subscribe(email);
        return channel.bind('OrderItems', function (data) {
            window.open(data.message, "_blank")
            setLoading(false)
        });
    }


    // useEffect(() => {

    //     var pusher = new Pusher('ac75d805a5b4f7e62a65', {
    //             cluster: 'eu'
    //           });
    //         var channel = pusher.subscribe(email);
    //         return channel.bind('OrderItems', function (data) {
    //             window.open(data.message)
    //             setLoading(false)
    //         });
    //     }, []);

    async function downloadCsvFile() {
        if (rangeValue?.from && rangeValue?.to) {
        try {
            setLoading(true);
            // warn("Taking longer time")
            callPusher();
            await downloadFile('/api/order_items', {...rangeValue, email}, getHeaders(), {error, success});
            // success("Your requested data will be exported")
        } 
        catch (e) {
            // error("Couldn't export requested order items")
            console.log(e);
        } finally {
            // setLoading(false)
        }
    }
    else {
        error("please select the desired date")
    }
}

    return (
        <fieldset>
            <legend> Order Item Export</legend>
            <DateRange
                format="YYYY-MM-dd"
                // maxInterval = {60}
                label="Item creation date"
                value={rangeValue}
                onChange={setRangeValue}
            />
            <div className={styles.Export__Button}>
                <Button
                    className={loading ? styles['Export__Button--loading'] : ''}
                    loading={loading}
                    onClick={downloadCsvFile}>
                    Export
                </Button>
            </div>
            {/* <div style={{
                display: 'block', width: '100%', padding: '0 7px' , color: '#767676',
                textTransform: 'capitalize'
            }}>The maximum duration for exporting is 60 days</div> */}
        </fieldset>
    );

}
