import classNamesBind from 'classnames/bind';
import React, {createElement} from "react";
import {NavLink} from 'react-router-dom';
import menuItems from "../../../../routes";
import styles from "./assets/SideBar.module.scss";

export default function SideBar({active, setActive}) {

    let MenuItems = menuItems();


    // initialize
    let classes = classNamesBind.bind(styles);
    const isMobile = () => window.innerWidth <= 800;

    // desktop handler
    function handleChange(val) {
        if (!isMobile()) setActive(val);
    }

    // mobile navigation handler
    function handleMobileSelect() {
        if (isMobile()) setActive(false);
    }

    return (
        <div className={
            classes(
                'sidebar__container',
                {'sidebar__container--active': active}
            )}
             onMouseEnter={() => handleChange(true)}
             onMouseLeave={() => handleChange(false)}>
            {MenuItems
                .filter(({icon}) => icon)
                .map(({label, icon, path}, i) => (
                    <NavLink
                        exact key={i} to={path}
                        onClick={handleMobileSelect}
                        className={styles.sidebar__element}
                        activeClassName={styles['sidebar__element--active']}>
                        {createElement(icon)}
                        <span className={styles.sidebar__label}>
                            {label}
                        </span>
                    </NavLink>
                ))}
        </ div>
    );

}