import React from "react";
import styles from "./assets/NotFound.module.scss";
import Page from "../../layouts/Page/Page";
import { UnderMaintaince } from '../UnderMaintaince/UnderMaintaince';

export function NotFound() {

    return (
        <Page>
            <div className="centered-content">
                <h1 className={styles.notfound__title}> Software is Under Maintaince </h1>
                <div className={styles.notfound__body}> Sorry for that we will back soon</div>
            </div>
        </Page>
    );

}
