import useAuth from "./hooks/useAuth";
import Dashboard from "./pages/Commercial_Dashboard/Dashboard";
import { Extract } from "./pages/Extract/Extract";
import { Login } from "./pages/Login/Login";
import RealTime from "./pages/RealTime/RealTime";
import { DashboardIcon, RealTimeIcon, ReportsIcon } from "./static";






const MenuItems = () => {

    const { getUser } = useAuth();
    const { group } = getUser();
    var menuItems = [];

    if (group == "Executive") {

        menuItems = [
            
            { label: 'Log In', component: Login, unprotected: true, path: '/sign-in' },
            { label: 'Real Time', component: RealTime, icon: RealTimeIcon, is_menu: true, path: '/' },
            // { label: 'DashBoard', component: Dashboard, icon: DashboardIcon, is_menu: true, path: '/dashboard' },
            { label: 'Extract', component: Extract, icon: ReportsIcon, is_menu: true, path: '/extract' },


        ];
    }
    else {
        menuItems = [
            { label: 'Log In', component: Login, unprotected: true, path: '/sign-in' },
            { label: 'Extract', component: Extract, icon: ReportsIcon, is_menu: true, path: '/' },

        ];

    }
    return menuItems;
}
export default MenuItems
