import {Menu, MenuBody, MenuHandle, MenuItem} from 'homzmart-components';
import React from "react";
import useAuth from "../../../../hooks/useAuth";
import {Logo, LogoSimple, KsaIcon} from "../../../../static";
import SearchBox from "../SearchBox/SearchBox";
import {ArrowDownIcon, MenuIcon, NotificationIcon, ProfileIcon, SignOutIcon} from "./assets/images";
import styles from "./assets/NavBar.module.scss";

export default function NavBar({toggleSidebar}) {

    // initialize
    const {logout, getUser} = useAuth();
    const {username} = getUser();
     // const name = username.replaceAll("/^\s*([a-zA-Z]).*\s+([a-zA-Z])\S+$/gm")
     const fullName = username?.split(' ');
     const initials = fullName?.shift().charAt(0) + fullName?.pop().charAt(0).toUpperCase();
     const name = initials;

    return (
        <div className={styles.navbar__container}>
            <MenuIcon
                onClick={toggleSidebar}
                className={styles.navbar__menuhandle}
            />
            <Logo className={styles.navbar__logo}/>
            <LogoSimple className={styles.navbar__logosimple}/>
            
            {/* <SearchBox/> */}
            <span className={styles.navbar__spacer}/>
            {/* <NotificationIcon className={styles.navbar__icon}/> */}
            <KsaIcon
            className={styles.navbar__flag}/>
            <Menu className={styles.navbar__usermenu}>
                <MenuHandle>
                    <div className={styles.navbar__handle}>
                        <div className={styles.navbar__usericon}>
                            <span> {name}</span>
                        </div>
                        <span className={styles.navbar__username}>
                            {username}
                        </span>
                        <ArrowDownIcon className={styles.navbar__icon}/>
                    </div>
                </MenuHandle>
                <MenuBody className={styles.navbar__menubody}>
                    <MenuItem disabled className={styles.navbar__menuname}>
                        {username}
                    </MenuItem>
            
                    <MenuItem onClick={logout}>
                        <SignOutIcon className={styles.navbar__menuicon}/>
                        Sign Out
                    </MenuItem>
                </MenuBody>
            </Menu>
        </div>
    );

}