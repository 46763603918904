import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useHistory } from 'react-router-dom';
import { Button, Input } from 'homzmart-components';
import useAuth from "../../hooks/useAuth";
import AuthPage from '../../layouts/AuthPage/AuthPage';
import styles from "./assets/Login.module.scss";

export function Login() {

    // initialize
    const history = useHistory();
    const { login, isTokenExpired } = useAuth();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState();

    // form
    async function onSubmit(data) {
        await login(data, setLoading);
    };

    // already logged in user
    useEffect(() => isTokenExpired() || history.push('/'), [history, isTokenExpired]);

    return (
        <AuthPage className={styles.login__container}>

            {/* Form */}
            <form className={styles.login__form} onSubmit={handleSubmit(onSubmit)}>

                {/* Header */}
                <span className={styles.login__formmeta}>
                    Welcome back
                </span>
                <h1 className={styles.login__formheader}>
                    Sign in to your account
                </h1>

                {/* Fields */}
                <Input
                    label="Email"
                    placeholder="Enter your email"
                    {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                    error={errors.email}
                    errorMessage={{
                        "Please enter your email to sign in": errors.email?.type === 'required',
                        "Please enter a valid email": errors.email?.type === 'pattern'
                    }}
                />
                <Input
                    type="password"
                    label="Password"
                    placeholder="Enter your password"
                    {...register('password', { required: true })}
                    error={errors.password}
                    errorMessage="Please enter your password to sign in"
                />
{/* 
                <div className={styles.login__forgetpass}>
                    <NavLink to="items">
                        Forgot Password ?
                    </NavLink> */}
                {/* </div> */}
                <Button
                    full
                    type="submit"
                    loading={loading}
                    className={styles.login__button}>
                    Sign In
                </Button>

            </form>

        </AuthPage>
    );

}
